import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="promo"
export default class extends Controller {
  static targets = ["promoCode"]

  connect() {}

  next() {
    if (this.promoCodeTarget && this.promoCodeTarget.value !== "") {
      location.href = `/promo/${this.promoCodeTarget.value}`
    }
  }
}
